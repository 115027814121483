@font-face {
  font-family: "interstate";
  src: url("./interstate/IntR.ttf");
}
@font-face {
  font-family: "interstate-bold";
  src: url("./interstate/INTBD___.ttf");
}
@font-face {
  font-family: "interstate-light";
  src: url("./interstate/INTLT___.ttf");
}